import { addMargin, introductionDesc } from "../util/vendors";
import gsap from "gsap";
import Siema from "siema";

export default {
	init() {
		// JavaScript to be fired on all pages
		const employeesCount = document.querySelector(".counter__final-score--employees").dataset.score;
		const locationsCount = document.querySelector(".counter__final-score--locations").dataset.score;

		if (window.innerWidth > 768) {
			addMargin(introductionDesc);
		}

		Number.prototype.numberFormat = function(decimals, decPoint, thousandsSep) {
			decPoint = typeof dec_point !== "undefined" ? decPoint : ".";
			thousandsSep = typeof thousands_sep !== "undefined" ? thousandsSep : " ";

			let parts = this.toFixed(decimals).split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSep);

			return parts.join(decPoint);
		};

		const digitsAnimate = () => {
			const counterDigits = document.querySelectorAll(".counter__score");

			let counter = { count1: 0, count2: 0 };
			let counterDigit1 = counterDigits[0];
			let counterDigit2 = counterDigits[1];

			TweenMax.to(counter, 4, {
				count1: employeesCount,
				onUpdate: function () {
					let formatNumber = counter.count1.numberFormat(0);
					counterDigit1.innerHTML = formatNumber;
				},
				ease: Circ.easeOut
			});

			TweenMax.to(counter, 4, {
				count2: locationsCount,
				onUpdate: function () {
					let formatNumber = counter.count2.numberFormat(0);
					counterDigit2.innerHTML = formatNumber;
				},
				ease: Circ.easeOut
			});
		};

		setTimeout(() => {
			if (window.innerWidth > 768) {
				digitsAnimate();
			}
		}, 1000);

		const counterSlider = new Siema({
			selector: "#counterSlider",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: function() {},
			onChange: changingClass
		});

		Siema.prototype.homepageSliderDots = function() {
			for (let i = 0; i < this.innerElements.length; i++) {
				const navWrapper = document.querySelector(".dots");
				const btn = document.createElement("button");

				btn.classList.add("dots__item");
				btn.addEventListener("click", () => this.goTo(i));

				navWrapper.appendChild(btn);

				const dots = Array.prototype.slice.call(document.querySelectorAll(".dots__item"));
				dots[0].classList.add("dots__item--active");
			}
		};

		function changingClass() {

			const dots = document.querySelectorAll(".dots__item");

			dots.forEach((slide, i) => {
				let addOrRemove = i === this.currentSlide ? "add" : "remove";
				dots[i].classList[addOrRemove]("dots__item--active");
			});
		};
		const setNumbers = () => {
			const counterScore = document.querySelectorAll(".counter__score");
			counterScore[1].textContent = `${employeesCount}`;
			counterScore[2].textContent = `${locationsCount}`;
			counterScore[3].textContent = `${employeesCount}`;
		};

		window.addEventListener("resize", () => {
			if (window.innerWidth < 550) {
				setNumbers();
				counterSlider.init();
			}

			if (window.innerWidth > 550) {
				counterSlider.destroy(true);
			}
		});

		if (window.innerWidth <= 550) {
			setNumbers();
			counterSlider.init();
		} else {
			counterSlider.destroy(true);
		}

		setInterval(() => {
			counterSlider.next()
		}, 3000);

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

const cardsEndpoint = `${window.location.origin}/wp-json/get/v1/all-cards`;
const postsEndpoint = `${window.location.origin}/wp-json/get/v1/all-posts`;

function initSearchForm(endpoint) {
	const cards = [];

	fetch(endpoint)
		.then(cards => cards.json())
		.then(data => cards.push(...data));

	function findCards(keyword, cards) {
		const searchString = keyword.toLowerCase();

		return cards.filter(card => {
			const title = card.title.toLowerCase();
			const category = card.category ? card.category.toLowerCase() : "";
			const details = card.details.toLowerCase();

			return title.search(searchString) !== -1 || category.search(searchString) !== -1 || details.search(searchString) !== -1;
		});
	}

	function displaySuggestions(e) {

		if (e.target.value.length > 0) {
			resetBtn.classList.add("search__icon--active");
		} else {
			resetBtn.classList.remove("search__icon--active");
		}

		const matchArray = findCards(this.value, cards);
		const suggestionsContainer = document.querySelector(".suggestions--full");

		if (suggestionsContainer.classList.contains("suggestions--disabled")) {
			suggestionsContainer.classList.remove("suggestions--disabled");
		}

		const html = matchArray.map(card => {
			const regex = new RegExp(this.value, "gi");
			const cardName = `${card.title}`;
			const cardTitle = cardName.replace(regex, `<span class="suggestions__highlight">${this.value}</span>`);
			const cardLink = card.url;

			return `<li class="suggestions__item"><a href="${cardLink}" target="_blank" class="suggestions__card"><span class="suggestions__desc"><span class="suggestions__desc suggestions__desc--category">${card.category === null ? "Produkt Trzuskawica" : card.category}</span><span class="suggestions__desc" style="${card.category === null ? "display: none;" : ""}"> | </span><span class="suggestions__desc suggestions__desc--details">${card.details}</span></span><br>${cardTitle}</a></li>`;
		}).join("");

		suggestionsTitle.textContent = matchArray.length;
		suggestions.innerHTML = html;

		if (this.value.length === 0) {
			suggestionsContainer.classList.add("suggestions--disabled");
		}

		if (!suggestionsContainer.classList.contains("suggestions--disabled")) {
			searchContainer.classList.add("search--animate");

			document.addEventListener("keydown", (e) => {
				if (e.keyCode === 27) {
					searchInput.value = "";
					suggestionsContainer.classList.add("suggestions--disabled");
					searchInput.focus();
				}
			});
		}

		document.querySelector(".suggestions--full").addEventListener("mouseleave", () => {
			suggestionsContainer.classList.add("suggestions--disabled");
		});
	}

	const searchContainer = document.querySelector(".search--full");
	const searchInput = document.querySelector(".search__input--menu");
	const suggestions = document.querySelector(".suggestions--full .suggestions__wrapper");
	const suggestionsTitle = document.querySelector(".search--full .suggestions__title span");

	searchInput.addEventListener("keyup", displaySuggestions);

	document.addEventListener("keydown", handleInputFocusTransfer);

	function handleInputFocusTransfer(e) {

		const focusableInputElements = document.querySelectorAll(".suggestions--full .suggestions__card");

		const focusable = [...focusableInputElements];
		const index = focusable.indexOf(document.activeElement);

		let nextIndex = 0;

		if (e.keyCode === 38) {
			e.preventDefault();
			nextIndex = index > 0 ? index - 1 : 0;
			focusableInputElements[nextIndex].focus();
		} else if (e.keyCode === 40) {
			e.preventDefault();
			nextIndex = index + 1 < focusable.length ? index + 1 : index;
			focusableInputElements[nextIndex].focus();
		} else if (e.keyCode === 8) {
			searchInput.focus();
		}
	}

	const resetBtn = document.querySelector(".search__icon--reset");

	function resetInput(e) {
		e.preventDefault();

		const suggestionsContainer = document.querySelector(".suggestions--full");

		searchInput.value = "";
		resetBtn.classList.remove("search__icon--active");
		suggestionsContainer.classList.add("suggestions--disabled");
	}

	resetBtn.addEventListener("click", resetInput);
	resetBtn.addEventListener("touchstart", resetInput);
}

export { cardsEndpoint, postsEndpoint, initSearchForm };
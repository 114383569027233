export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('pojedyncze archiwum');

		const wordpressPagination = document.querySelector(".page-numbers");

		const rebuildPagination = (element) => {

			element.classList.remove("page-numbers");
			element.classList.add("pagination");

			let listElements = Array.prototype.slice.call(element.children);

			listElements.forEach((item) => {
				item.classList.add("pagination__item");
			});

		};

		rebuildPagination(wordpressPagination);

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
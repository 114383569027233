import { addMargin, introductionDesc } from "../util/vendors.js";
import { cardsEndpoint } from "../util/search";

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('archiwum');

		const setPosition = () => {
			const label = document.querySelector(".search__label");
			const input = document.querySelector(".search__input");

			// label.style.minHeight = "60px";
			// input.style.padding = "27px 20px 21px 60px";
		};

		function getOS() {
			let userAgent = window.navigator.userAgent,
				platform = window.navigator.platform,
				macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
				windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
				iosPlatforms = ["iPhone", "iPad", "iPod"],
				os = null;

			if (macosPlatforms.indexOf(platform) !== -1) {
				os = "Mac OS";
				// setPosition();
			} else if (iosPlatforms.indexOf(platform) !== -1) {
				os = "iOS";
				// setPosition();
			} else if (windowsPlatforms.indexOf(platform) !== -1) {
				os = "Windows";
			} else if (/Android/.test(userAgent)) {
				os = "Android";
			} else if (!os && /Linux/.test(platform)) {
				os = "Linux";
			}

			return os;
		}

		getOS();

		const wordpressPagination = document.querySelector(".page-numbers");

		const rebuildPagination = (element) => {

			element.classList.remove("page-numbers");
			element.classList.add("pagination");

			let listElements = Array.prototype.slice.call(element.children);

			listElements.forEach((item) => {
				item.classList.add("pagination__item");
			});
		};

		function initSearchForm(endpoint) {

			const cards = [];

			fetch(endpoint)
				.then(cards => cards.json())
				.then(data => cards.push(...data));

			function findCards(keyword, cards) {
				const searchString = keyword.toLowerCase();

				return cards.filter(card => {
					const title = card.title.toLowerCase();
					const category = card.category ? card.category.toLowerCase() : "";
					const details = card.details.toLowerCase();

					return title.search(searchString) !== -1 || category.search(searchString) !== -1 || details.search(searchString) !== -1;
				});
			}

			function displaySuggestions(e) {

				if (e.target.value.length > 0) {
					resetBtn.classList.add("search__icon--active");
				} else {
					resetBtn.classList.remove("search__icon--active");
				}

				const matchArray = findCards(this.value, cards);
				const suggestionsContainer = document.querySelector(".suggestions--page");

				if (suggestionsContainer.classList.contains("suggestions--disabled")) {
					suggestionsContainer.classList.remove("suggestions--disabled");
				}

				const html = matchArray.map(card => {
					const regex = new RegExp(this.value, "gi");
					const cardName = `${card.title}`;
					const cardTitle = cardName.replace(regex, `<span class="suggestions__highlight">${this.value}</span>`);
					const cardLink = card.url;

					return `<li class="suggestions__item"><a href="${cardLink}" target="_blank" class="suggestions__card"><span class="suggestions__desc"><span class="suggestions__desc suggestions__desc--category">${card.category === null ? "Produkt Trzuskawica" : card.category}</span><span class="suggestions__desc" style="${card.category === null ? "display: none;" : ""}"> | </span><span class="suggestions__desc suggestions__desc--details">${card.details}</span></span><br>${cardTitle}</a></li>`;
				}).join("");

				suggestionsTitle.textContent = matchArray.length;
				suggestions.innerHTML = html;

				if (this.value.length === 0) {
					suggestionsContainer.classList.add("suggestions--disabled");
				}

				if (!suggestionsContainer.classList.contains("suggestions--disabled")) {
					document.addEventListener("keydown", (e) => {
						if (e.keyCode === 27) {
							searchInput.value = "";
							suggestionsContainer.classList.add("suggestions--disabled");
							searchInput.focus();
						}
					});
				}

				document.querySelector(".suggestions--page").addEventListener("mouseleave", () => {
					suggestionsContainer.classList.add("suggestions--disabled");
				});
			}

			const searchInput = document.querySelector(".search__input--page");
			const suggestions = document.querySelector(".suggestions--page .suggestions__wrapper");
			const suggestionsTitle = document.querySelector(".search--page .suggestions__title span");

			searchInput.addEventListener("keyup", displaySuggestions);

			document.addEventListener("keydown", handleInputFocusTransfer);

			const scrollTo = (element, distance) => {
				window.scroll({
					behavior: "smooth",
					left: 0,
					top: element.offsetTop - distance
				});
			};

			searchInput.addEventListener("touchstart", (e) => {
				scrollTo(document.querySelector(".introduction__title--products"), 150);
				searchInput.focus();
			});

			function handleInputFocusTransfer(e) {

				const focusableInputElements = document.querySelectorAll(".suggestions--page .suggestions__card");

				const focusable = [...focusableInputElements];
				const index = focusable.indexOf(document.activeElement);

				let nextIndex = 0;

				if (e.keyCode === 38) {
					e.preventDefault();
					nextIndex = index > 0 ? index - 1 : 0;
					focusableInputElements[nextIndex].focus();
				} else if (e.keyCode === 40) {
					e.preventDefault();
					nextIndex = index + 1 < focusable.length ? index + 1 : index;
					focusableInputElements[nextIndex].focus();
				} else if (e.keyCode === 8) {
					searchInput.focus();
				}
			}

			const resetBtn = document.querySelector(".search__icon--reset");

			function resetInput(e) {
				e.preventDefault();

				const suggestionsContainer = document.querySelector(".suggestions--page");

				searchInput.value = "";
				resetBtn.classList.remove("search__icon--active");
				suggestionsContainer.classList.add("suggestions--disabled");
			}

			resetBtn.addEventListener("click", resetInput);
			resetBtn.addEventListener("touchstart", resetInput);
		}

		if (window.location.pathname === "/karty-produktow/" || document.body.classList.contains("search")) {
			initSearchForm(cardsEndpoint);
		}

		if (document.body.classList.contains("search")) {
			rebuildPagination(wordpressPagination);
		}

		if (window.location.pathname === "/produkty/") {
			console.log('produkty');

			const lang = document.querySelector("html").getAttribute("lang");

			if (window.innerWidth > 768) {
				addMargin(introductionDesc);

				const groups = document.querySelectorAll(".group");

				groups.forEach((element) => {
					element.addEventListener("mouseenter", (e) => {
						groups.forEach(element => element.classList.add("group--hidden"));
						e.currentTarget.classList.remove("group--hidden");
					});

					element.addEventListener("mouseleave", (e) => {
						groups.forEach(element => element.classList.remove("group--hidden"));
					});
				});

				const products = document.querySelectorAll("[data-product]");

				products.forEach((element) => {
					element.addEventListener("click", (e) => {
						if (lang === "pl-PL") {
							window.location.href = `/produkt/${element.getAttribute("data-product").toLocaleLowerCase()}`;
						} else {
							window.location.href = `/produkt/${element.getAttribute("data-product").toLocaleLowerCase()}?lang=en`;
						}
					});
				});
			} else {

				const products = document.querySelectorAll("[data-product]");
				const productCategory = document.querySelector(".circle__category");
				const circleBtn = document.querySelector(".circle__btn");

				let color = "#a8cf7f";

				document.documentElement.style.setProperty("--btnColor", `${color}`);
				if (lang === "pl-PL") {
					circleBtn.setAttribute("href", `${window.location.origin}/produkt/bielik`);
				} else {
					circleBtn.setAttribute("href", `${window.location.origin}/produkt/bielik?lang=en`);
				}

				products.forEach((element) => {
					element.addEventListener("click", (e) => {

						e.preventDefault();

						console.log(e.currentTarget.parentElement.getAttribute("data-category"));

						productCategory.textContent = `${e.currentTarget.parentElement.getAttribute("data-category")}`;
						circleBtn.innerHTML = `<span class="circle__name"><img src="${window.origin}/wp-content/uploads/2020/09/${e.currentTarget.getAttribute("data-product").toLowerCase()}.svg" class="circle__icon" alt="${e.currentTarget.getAttribute("data-product")}">${e.currentTarget.getAttribute("data-product")}</span><span class="circle__more">${lang === "pl-PL" ? "Dowiedz się więcej" : "Learn more"}</span>`;
						if (lang === "pl-PL") {
							circleBtn.setAttribute("href", `${window.location.origin}/produkt/${e.currentTarget.getAttribute("data-product").toLowerCase()}`);
						} else {
							circleBtn.setAttribute("href", `${window.location.origin}/produkt/${e.currentTarget.getAttribute("data-product").toLowerCase()}?lang=en`);
						}

						switch (e.currentTarget.getAttribute("data-product")) {
							case "Bielik":
								color = "#a8cf7f";
								break;
							case "Domplast":
								color = "#58b24a";
								break;
							case "Domcalc":
								color = "#408838";
								break;
							case "Agrobielik":
								color = "#4ebe93";
								break;
							case "Sanitlime":
								color = "#5ea6b7";
								break;
							case "Purelime":
								color = "#257293";
								break;
							case "Aqualime":
								color = "#0f4d73";
								break;
							case "Alcalime":
								color = "#fecc52";
								break;
							case "Glasslime":
								color = "#f4a836";
								break;
							case "Paperlime":
								color = "#f28e2b";
								break;
							case "Ferrolime":
								color = "#ea7205";
								break;
							case "Strongbet":
								color = "#aca8b4";
								break;
							case "Stoneway":
								color = "#64556d";
								break;
							case "Clavical":
								color = "#423445";
								break;
						}

						document.documentElement.style.setProperty("--btnColor", `${color}`);

					});
				});
			}
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
import * as ScrollMagic from "scrollmagic";
import { gsap } from 'gsap';
import Siema from 'siema';

export default {

	init() {

		console.log('home');

		const mainTitle = document.querySelector(".header__title--big");
		const mainDescription = document.querySelector(".header__desc--main");
		const mainBtn = document.querySelector(".header__btn");
		const sectorItems = document.querySelectorAll(".sectors__item");

		let tl = gsap.timeline();

		gsap.to(mainTitle, 1, {immediateRender: false, delay: .3, opacity: 1}, 5);
		gsap.to(mainDescription, 1, {immediateRender: false, delay: .5, opacity: 1});
		gsap.to(mainBtn, 1, {immediateRender: false, delay: .8, opacity: 1});

		tl.staggerTo(sectorItems, 1, {immediateRender: false, delay: .3, opacity: .5}, .3);

		setTimeout(() => {
			sectorItems[0].classList.add("sectors__item--active");
		}, 2000);

		const progressbarTween = (duration) => {
			const progressbar = document.querySelector(".slider__bar");

			gsap.fromTo(progressbar, duration, {immediateRender: true, width: 0, ease: "power0"}, {
				width: "100%",
				ease: "power0"
			});
		};

		const headerSlider = new Siema({
			selector: "#homepageSlider",
			duration: 200,
			easing: "ease-out",
			perPage: 1,
			startIndex: 0,
			draggable: true,
			multipleDrag: true,
			threshold: 20,
			loop: true,
			rtl: false,
			onInit: function() {
				
				this.resizeHandler();
				
				let sliderFrame = this.sliderFrame;

				sliderFrame.style.height = "100%";

				let sliderElements = this.sliderFrame.childNodes;

				sliderElements.forEach((slide) => {
					slide.style.height = "100%";
				});

				let elements = this.innerElements;

				for (let i = 0; i < elements.length; i++) {
					elements[i].setAttribute("data-slide", i + 1);
				}

				for (let i = 0; i < sectorItems.length; i++) {
					sectorItems[i].setAttribute("data-slide", i + 1);
				}

				progressbarTween(10);

			},
			onChange: function() {

				sectorItems.forEach((slide, i) => {
					let addOrRemove = i === this.currentSlide ? "add" : "remove";
					sectorItems[i].classList[addOrRemove]("sectors__item--active");

					sectorsSlider.goTo(this.currentSlide);
				});

				progressbarTween(10);

			}
		});

		Siema.prototype.customResizer = function() {
			window.addEventListener("resize", () => {

				let sliderFrame = this.sliderFrame;

				sliderFrame.style.height = "100%";

				let sliderElements = this.sliderFrame.childNodes;

				sliderElements.forEach((slide) => {
					slide.style.height = "100%";
				});
			});
		};

		headerSlider.customResizer();

		const prevSlide = () => {
			sliderAutoPlayReset();
			headerSlider.prev();
		};

		const nextSlide = () => {
			sliderAutoPlayReset();
			headerSlider.next();
		};

		let sliderAutoPlay = setInterval(nextSlide, 10000);

		const sliderAutoPlayReset = () => {
			clearInterval(sliderAutoPlay);
			sliderAutoPlay = setInterval(nextSlide, 10000);
		};

		document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
		document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);

		/* Slider (Sektory) */

		const sectorsSlider = new Siema({
			selector: "#sectorsSlider",
			duration: 200,
			easing: "ease-out",
			perPage: {
				1600: 4,
				1024: 3,
				768: 2
			},
			startIndex: 0,
			draggable: false,
			multipleDrag: false,
			threshold: 20,
			loop: false,
			rtl: false,
			onInit: function() {

			},
			onChange: function() {

			}
		});

		const places = document.querySelectorAll("[data-place]");
		const markers = document.querySelectorAll(".map__marker");

		/* Scroll Magic */

		const controller = new ScrollMagic.Controller({addIndicators: true});

		new ScrollMagic.Scene()
			.addTo(controller);

		/* Aktualności */

		const articles = document.querySelectorAll(".news__item");

		const articlesScene = new ScrollMagic.Scene({
			triggerElement: ".news",
			offset: -350,
			duration: 0,
			reverse: false
		})
			.addTo(controller)
			.setClassToggle(".news", "animate")

		/* Standardy */

		const standardsScene = new ScrollMagic.Scene({
			triggerElement: ".standards",
			offset: -150,
			duration: 0,
			reverse: false
		})
			.addTo(controller)
			.setClassToggle(".standards", "animate")

		/* Kariera */

		const careerScene = new ScrollMagic.Scene({
			triggerElement: ".career",
			offset: -150,
			duration: 0,
			reverse: false
		})
			.addTo(controller)
			.setClassToggle(".career", "animate")

		/* Kontakt */

		const contactScene = new ScrollMagic.Scene({
			triggerElement: ".contact",
			offset: -150,
			duration: 0,
			reverse: false
		})
			.addTo(controller)
			.setClassToggle(".contact", "animate")

		/* Stopka */


		const footerScene = new ScrollMagic.Scene({
			triggerElement: ".footer",
			offset: -350,
			duration: 0,
			reverse: false
		})
			.addTo(controller)
			.setClassToggle(".footer", "animate")

		const activeMarker = (place) => {
			let placeName = place.currentTarget.getAttribute("data-place");

			if (placeName == "Zakład Kujawy") {
				markers[0].classList.toggle("map__marker--active");
				markers[1].classList.toggle("map__marker--inactive");
				markers[2].classList.toggle("map__marker--inactive");
			}

			if (placeName == "Kopalnia Bazaltu Targowica") {
				markers[0].classList.toggle("map__marker--inactive");
				markers[1].classList.toggle("map__marker--active");
				markers[2].classList.toggle("map__marker--inactive");
			}

			if (placeName == "Zakład Sitkówka") {
				markers[0].classList.toggle("map__marker--inactive");
				markers[1].classList.toggle("map__marker--inactive");
				markers[2].classList.toggle("map__marker--active");
			}
		};

		const removeMarkers = () => {
			markers.forEach((marker) => {
				marker.classList.remove("map__marker--inactive");
				marker.classList.remove("map__marker--active");
			});
		};

		places.forEach((place) => {
			place.addEventListener("mouseenter", activeMarker);
			place.addEventListener("focus", activeMarker);
			place.addEventListener("mouseleave", removeMarkers);
			place.addEventListener("blur", removeMarkers);
		});

		const environment = document.querySelector(".environment");

		const addPadding = (element) => {

			const safetyTitle = document.querySelector(".standards__title--safety");
			let position = `${safetyTitle.offsetTop - 100}px`;

			element.style.paddingTop = `${position}`;
		};

		addPadding(environment);

		window.addEventListener("resize", () => {
			if (window.innerWidth >= 768) {
				addPadding(environment);
			} else {
				environment.style.paddingTop = 0;
			}
		});
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};

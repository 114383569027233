export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('blog');

		const btnAjax = document.querySelector(".btn__ajax");
		const newsWrapper = document.querySelector(".news__wrapper");

		let increment = 1;
		const postsArray = [];

		const getPosts = () => {

			let endpoint = `${window.location.origin}/wp-json/wp/v2/posts?per_page=4&page=${++increment}&orderby=date&order=desc&type=post`;

			fetch(endpoint)
				.then((response) => {
					if (response.ok) {
						response.json().then(posts => {
							posts.forEach((post) => {
								postsArray.push(post);

								let html = `<a href="${post.link}" class="news__item news__item--blog">
										<img src="${post.featured_img_src}" class="news__image" alt="">
										<p class="news__category">Aktualności</p>
										<p class="news__title news__title--article">${post.title.rendered}</p>
										<object><a href="${post.link}" class="news__link">Czytaj więcej</a></object>
									</a>`;

								newsWrapper.insertAdjacentHTML("beforeend", html);
							});
							let totalPages = parseInt(response.headers.get('X-WP-TotalPages'));
							console.log(totalPages < increment);
							if (totalPages < increment) {
								btnAjax.setAttribute("disabled", true);
							}
						});
					} else {

					}
				});
			};

		btnAjax.addEventListener("click", getPosts);

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};

import Siema from "siema";

export default {

	init() {

		console.log('single');

		const body = document.querySelector("body");

		if (body.classList.contains("single-post") || body.classList.contains("single-oferty-pracy")) {
			if (window.innerWidth > 768) {
				body.style.overflowX = "unset";
			} else {
				body.style.overflow = "hidden";
			}
		}

		if (body.classList.contains("single-oferty-pracy")) {
			const careerSlider = new Siema({
				selector: "#careerSlider",
				duration: 200,
				easing: "ease-out",
				perPage: 1,
				startIndex: 0,
				draggable: true,
				multipleDrag: true,
				threshold: 20,
				loop: true,
				rtl: false,
				onInit: function () {
				},
				onChange: changingClass
			});

			Siema.prototype.homepageSliderDots = function () {
				for (let i = 0; i < this.innerElements.length; i++) {
					const navWrapper = document.querySelector(".dots");
					const btn = document.createElement("button");

					btn.classList.add("dots__item");
					btn.addEventListener("click", () => this.goTo(i));

					navWrapper.appendChild(btn);

					const dots = Array.prototype.slice.call(document.querySelectorAll(".dots__item"));
					dots[0].classList.add("dots__item--active");
				}
			};

			function changingClass() {

				const dots = document.querySelectorAll(".dots__item");

				dots.forEach((slide, i) => {
					let addOrRemove = i === this.currentSlide ? "add" : "remove";
					dots[i].classList[addOrRemove]("dots__item--active");
				});
			};

			careerSlider.homepageSliderDots();

			window.addEventListener("resize", () => {
				if (window.innerWidth < 550) {
					careerSlider.init();
				}

				if (window.innerWidth > 550) {
					careerSlider.destroy(true);
				}
			});

			if (window.innerWidth <= 550) {
				careerSlider.init();
			} else {
				careerSlider.destroy(true);
			}
		}

		if (body.classList.contains("single-post")) {
			if (!body.classList.contains("post-template")) {
				const shareBtns = document.querySelector(".share");
				const postParagraph = document.querySelectorAll("p.post__paragraph");

				const setPosition = (element) => {
					const firstElement = document.querySelector(".post__content");
					const firstParagraph = firstElement.firstElementChild;
					const style = window.getComputedStyle(firstParagraph);

					let marginElement = style.getPropertyValue("margin-bottom");
					let heightElement = firstParagraph.offsetHeight;

					element.style.transform = `translateY(${heightElement + parseInt(marginElement, 10)}px)`;
				};

				const disableBtns = () => {
					const postContent = document.querySelector(".post__wrapper--main");
					const shareContent = postContent.firstElementChild;
					const shareList = shareContent.firstElementChild;

					shareContent.style.height = `${shareContent.offsetHeight - shareList.offsetHeight}px`;

				};

				setTimeout(() => {
					if (postParagraph.length !== 0) {
						setPosition(shareBtns);
					}
					shareBtns.classList.remove("share--hidden");
					disableBtns();
				}, 1000);

				function copyTextToClipboard(text) {
					const textArea = document.createElement("textarea");

					textArea.style.position = "fixed";
					textArea.style.top = 0;
					textArea.style.left = 0;
					textArea.style.width = "2em";
					textArea.style.height = "2em";
					textArea.style.padding = 0;
					textArea.style.border = "none";
					textArea.style.outline = "none";
					textArea.style.boxShadow = "none";
					textArea.style.background = "transparent";

					textArea.value = text;

					document.body.appendChild(textArea);

					textArea.select();

					try {
						const successful = document.execCommand("copy");
						const msg = successful ? "successful" : "unsuccessful";
						console.log("Link został skopiowany poprawnie!");
					} catch (err) {
						console.log("Link nie został skopiowany!");
					}

					document.body.removeChild(textArea);
				}

				function copyURL() {
					copyTextToClipboard(location.href);
				}

				document.querySelector(".share__item--clipboard").addEventListener("click", function (e) {
					e.preventDefault();
					copyURL();
				});
			}

			/* Koło (Prezentacja) */

			const groups = document.querySelectorAll(".group");

			groups.forEach((element) => {
				element.addEventListener("mouseenter", (e) => {
					groups.forEach(element => element.classList.add("group--hidden"));
					e.currentTarget.classList.remove("group--hidden");
				});

				element.addEventListener("mouseleave", (e) => {
					groups.forEach(element => element.classList.remove("group--hidden"));
				});
			});

			const products = document.querySelectorAll("[data-product]");

			products.forEach((element) => {
				if (window.innerWidth >= 768) {
					element.addEventListener("click", () => {
						window.location.href = `/produkt/${element.getAttribute("data-product").toLocaleLowerCase()}`
					});
				}
			});

			const productCategory = document.querySelector(".products__category");
			const productTitle = document.querySelector(".products__title");
			const productDesc = document.querySelector(".products__desc");
			const productBtn = document.querySelector(".products__btn");

			productBtn.setAttribute("href", `${window.location.origin}/produkt/bielik`);

			products.forEach((element) => {
				element.addEventListener("mouseenter", (e) => {

					e.preventDefault();

					productCategory.textContent = `${e.currentTarget.parentElement.getAttribute("data-category")}`;
					productTitle.textContent = `${e.currentTarget.getAttribute("data-product")}`;
					productDesc.textContent = `${e.currentTarget.getAttribute("data-desc")}`;
					productBtn.setAttribute("href", `${window.location.origin}/produkt/${e.currentTarget.getAttribute("data-product").toLowerCase()}`);
				});

				element.addEventListener("click", (e) => {

					e.preventDefault();

					productCategory.textContent = `${e.currentTarget.parentElement.getAttribute("data-category")}`;
					productTitle.textContent = `${e.currentTarget.getAttribute("data-product")}`;
					productDesc.textContent = `${e.currentTarget.getAttribute("data-desc")}`;
					productBtn.setAttribute("href", `${window.location.origin}/produkt/${e.currentTarget.getAttribute("data-product").toLowerCase()}`);
				});
			});

			/* Film (Prezentacja) */

			const movie = document.querySelector(".movie__video");
			const movieContainer = document.querySelector(".movie__full");
			const btnPlay = document.querySelector(".movie__btn--play");
			const btnClose = document.querySelector(".movie__btn--close");

			btnPlay.addEventListener("click", () => {

				movieContainer.classList.toggle("movie__full--is-open");

				if (movieContainer.classList.contains("movie__full--is-open")) {
					btnPlay.classList.toggle("movie__btn--hidden");
					btnClose.classList.toggle("movie__btn--hidden");
				}
			});

			btnClose.addEventListener("click", () => {

				movie.setAttribute("src", null);
				movieContainer.classList.toggle("movie__full--is-open");

				setTimeout(() => {
					movie.setAttribute("src", "https://player.vimeo.com/video/491137915");
				}, 1000);

				if (!movie.classList.contains("movie__full--is-open")) {
					btnPlay.classList.toggle("movie__btn--hidden");
					btnClose.classList.toggle("movie__btn--hidden");
				}
			});
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};

import { addMargin, introductionDesc } from "../util/vendors";
import Siema from "siema";

export default {
	init() {

		console.log('pojedynczy produkt');

		const body = document.body.classList
		const breadcrumbLink = document.querySelector(".breadcrumbs__link");

		if (body.contains("single-produkty")) {
			breadcrumbLink.classList.remove("breadcrumbs__link--variant");
		}

		if (window.innerWidth > 768) {
			addMargin(introductionDesc);
		}

		/* Nagłówki */

		window.addEventListener("load", function() {
			const headerBars = () => {
				const bars = document.querySelector(".bars");
				bars.querySelector(".bars__color").style.width = `${bars.querySelector(".bars__top").getBoundingClientRect().width - bars.querySelector(".bars__header").getBoundingClientRect().width}px`;
			}

			headerBars();
		});

		/* Karty charakterystyki produktu */

		const cardsContainer = document.querySelector("#cardsSlider");

		if (window.innerWidth > 768) {

			if (document.querySelectorAll("#cardsSlider .cards__item").length > 4) {

				const cardsSlider = new Siema({
					selector: "#cardsSlider",
					duration: 200,
					easing: "ease-out",
					perPage: {
						1360: 4,
						960: 3,
						480: 2
					},
					startIndex: 0,
					draggable: false,
					multipleDrag: false,
					threshold: 20,
					loop: false,
					rtl: false,
					onInit: function() {
						const productLenngth = document.querySelector(".product__length span");
						productLenngth.textContent = `${this.innerElements.length}`;

						this.innerElements.forEach((slide, index) => {
							slide.querySelector(".cards__no").textContent = `${index + 1}`;
						});
					},
					onChange: changingClass
				});

				const prevSlide = () => {
					cardsSlider.prev();
				};

				const nextSlide = () => {
					cardsSlider.next();
				};

				document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
				document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);

				Siema.prototype.addPagination = function() {

					const btnsWrapper = document.querySelector(".slider-nav");

					for (let i = 0; i < (this.innerElements.length / this.perPage); i++) {

						const btn = document.createElement("button");
						btn.classList.add("slider-nav__item");
						btn.addEventListener("click", (e) => {
							this.goTo(i);
						});
						btnsWrapper.appendChild(btn);

						const dots = document.querySelectorAll(".slider-nav__item");
						dots[0].classList.add("slider-nav__item--active");
					}
				}

				cardsSlider.addPagination();

				function changingClass() {

					const dots = document.querySelectorAll(".slider-nav__item");

					dots.forEach((slide, i) => {
						let addOrRemove = i === this.currentSlide ? "add" : "remove";
						dots[i].classList[addOrRemove]("slider-nav__item--active");
					});
				};

			} else {
				document.querySelector(".slider__btns").style.display = "none";
				cardsContainer.classList.add("no-slider");
				document.querySelectorAll("#cardsSlider .cards__item").forEach((item, index) => {
					item.querySelector(".cards__no").textContent = `${index + 1}`;
					item.classList.remove("cards__item--slide");
				});
				document.querySelector(".product__length span").textContent = Array.prototype.slice.call(document.querySelectorAll(".no-slider .cards__item")).length;
			}
		} else {

			const cardsSlider = new Siema({
				selector: "#cardsSlider",
				duration: 200,
				easing: "ease-out",
				perPage: {
					1360: 4,
					960: 3,
					480: 2
				},
				startIndex: 0,
				draggable: false,
				multipleDrag: false,
				threshold: 20,
				loop: false,
				rtl: false,
				onInit: function() {},
				onChange: function() {}
			});

			const prevSlide = () => {
				cardsSlider.prev();
			};

			const nextSlide = () => {
				cardsSlider.next();
			};

			document.querySelector(".slider__btn--prev").addEventListener("click", prevSlide);
			document.querySelector(".slider__btn--next").addEventListener("click", nextSlide);
		}
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
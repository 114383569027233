function checkBrowser() {
    function dismiss() {
        document.getElementById('browser-update-modal').classList.remove('show');
    }

    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    var trident = ua.indexOf('Trident/');

    if (msie > 0 || trident > 0) {
        window.onload = function() {
            document.getElementById('browser-update-modal').classList.add('show');
            document.getElementById('browser-update-close').onclick = dismiss;
        }
    }

}

export default checkBrowser;

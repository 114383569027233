import { addMargin, introductionDesc } from "../util/vendors";

export default {
	init() {
		// JavaScript to be fired on all pages
		console.log('sektory');

		if (window.innerWidth > 768) {
			addMargin(introductionDesc);
		}

	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
